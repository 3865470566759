import { FC, useEffect, useState } from 'react';
import styles from '../HomePage/video.module.css';
import useDelayRouteExit from 'delay-react-route-exit';

export interface IWindowWeight {
    width:number;
}

const ContactUs:FC<IWindowWeight> = ({width}) => {

    const [isExitingRoute, setIsExitingRoute] = useState<boolean>();

    // eslint-disable-next-line react-hooks/rules-of-hooks
        useDelayRouteExit(1500, () => {
        setIsExitingRoute(true);
    });

    useEffect(() => {

        var iframe = document.getElementById('myiframe') as HTMLIFrameElement;

        if(iframe) {
            const iframeWindow = iframe.contentWindow;
            const iframeDocument = iframeWindow ? iframeWindow.document : null;

            iframe.onload = () => {
    
                if (iframeDocument) {
                    const body = iframeDocument.body;
                
                    if (body) {
                        body.style.fontFamily = "'LuxoraGoretskThin', sans-serif !important";
                        body.style.backgroundColor = '#c9c9c94a !important';
                        body.style.backdropFilter = 'blur(5px) !important';
                    }
                }       
            } 
        }

        if(width < 1023) {
            document.getElementById('responsiveCard')!.style.width = '360px';

        }
    }, [width])
    

    return (
        <>
            <div 
                id='responsiveCard'
                className={`${styles.cards} ${isExitingRoute ? " animate__animated animate__fadeOutDown " : "animate__animated animate__fadeInUp"}`}
                style={isExitingRoute ? {animationDelay:'0.6s',animationDuration:'0.3s', display: 'flex', justifyContent: 'center', alignItems: 'center'} : {animationDelay:'1.5s', animationDuration: '1s', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <div  className={`${isExitingRoute ? " animate__animated animate__fadeOutLeft " : "animate__animated animate__fadeInLeft"}`} 
                    style={isExitingRoute ? { animationDelay:'0.5s',animationDuration:'0.3s', textAlign: 'center' } : {animationDelay:'2.1s', animationDuration: '1s', textAlign: 'center' }} >
                        <a style={{ fontSize:'30px'}} href="mailto:hello@genarion.com">hello@genarion.com</a>
                </div>
            </div>
            </>
    )
}


export default ContactUs;